/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import Link from "next/link";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
// import Image from "next/image";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";
import SearchForm from "./SearchForm";
import useWindowSize from "../../hooks/useWindowSize";
import { convertToImageKit } from "../../utils/convert-to-imagekit";
import styles from "./Navbar.module.scss";
import Login from "../../components/Quiz/Login";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../redux/slices/auth";
import toast, { Toaster } from "react-hot-toast";
import { Menu } from "@headlessui/react";
import { AiFillCaretDown } from "react-icons/ai";
import { FiLogOut } from "react-icons/fi";
import { BiUser } from "react-icons/bi";
import { useRouter } from "next/router";
import LazyLoad from "react-lazyload";

const defaultUser =
  "https://toppernotes.s3.ap-south-1.amazonaws.com/images/profiles/f6Mx15mMFkJtJBTYZuAC.png";

const header =
  "https://toppersnotes-web.s3.ap-south-1.amazonaws.com/Gallery/Thumbnails/tn-logo-header.png";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function Header({ data }) {
  const [openExamStore, setOpenExamStore] = useState(false);
  const [openCurrentAffairs, setOpenCurrentAffairs] = useState(false);
  const [openTestSeries, setOpenTestSeries] = useState(false);
  const router = useRouter();
  const size = useWindowSize();
  const [currentAffairs, setCurrentAffairs] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const dispatch = useDispatch();
  const [dropdown, setDropdown] = useState(false);

  useEffect(() => {
    if (data && data.categories) {
      data.categories.map((item) => {
        item.href = `/categories/${item.slug}`;
      });

      setCategoriesList([...data.categories]);
    }
  }, [JSON.stringify(data)]);

  useEffect(() => {
    const getCurrentAffairs = async () => {
      if (data && data.currentAffairs) {
        data.currentAffairs.map((c_Affairs) => {
          c_Affairs.href =
            c_Affairs &&
            c_Affairs.type === "static" &&
            c_Affairs.view === "simple"
              ? `/current-affairs/${c_Affairs.slug}`
              : c_Affairs.type === "dynamic" && c_Affairs.view === "detailed"
              ? `/current-affairs/english-current-affair`
              : c_Affairs.type === "dynamic" && c_Affairs.view === "simple"
              ? `/current-affairs/articles/${c_Affairs.slug}`
              : "#";
        });

        setCurrentAffairs(data.currentAffairs);
      }
    };

    getCurrentAffairs();
  }, [JSON.stringify(data)]);

  const myLoader = ({ src }) => {
    // return src + `?tr=w-${size.width}`;
    return src + `?tr=w-360`;
  };
  const [openLoginModal, setOpenLoginModal] = useState(false);

  const {
    auth: { isAuthenticated, user },
  } = useSelector((state) => {
    return {
      auth: state.auth,
    };
  });

  return (
    <Popover className="relative bg-white">
      <div className="flex justify-between items-center px-4 py-6 sm:px-6 lg:justify-start lg:space-x-10">
        <div>
          <span className="sr-only">Workflow</span>
          <Link href="/">
            <LazyLoad once>
              <img
                className="cursor-pointer"
                width={160}
                height={65}
                src={convertToImageKit(header)}
                alt="Navbar"
                onClick={() => router.push("/")}
              />
              {/* <Image
                unoptimized={true}
                loader={myLoader}
                width={160}
                height={65}
                src={convertToImageKit(header)}
                alt="Navbar"
                priority={true}
              /> */}
            </LazyLoad>
          </Link>
        </div>

        <div className="-mr-2 -my-2 lg:hidden">
          <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
            <span className="sr-only">Open menu</span>
            <MenuIcon className="h-6 w-6" aria-hidden="true" />
          </Popover.Button>
        </div>
        <div className="hidden lg:flex-auto lg:flex lg:items-center lg:justify-between">
          <div className="flex flex-row items-center gap-12">
            <Popover.Group as="nav" className="flex space-x-10">
              {/* <Link
              href="/featured/"
              className="text-base font-medium text-gray-500 hover:text-gray-900"
            >
              Featured Courses
            </Link> */}

              <div className={`${styles.hoverTriger} relative lg:!ml-3`}>
                <div
                  className={`text-gray-900 group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                >
                  <Link
                    href="/categories"
                    className="text-base font-medium text-gray-500 hover:text-gray-900"
                  >
                    <a>Notes</a>
                  </Link>
                  <ChevronDownIcon
                    className={classNames(
                      "text-gray-600",
                      "ml-2 h-5 w-5 group-hover:text-gray-500"
                    )}
                    aria-hidden="true"
                  />
                </div>

                <div
                  className={`${styles.hoverTarget} absolute z-10 left-1/2 transform -translate-x-1/2 w-screen max-w-xs `}
                >
                  <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                    <ul
                      role="list"
                      className="relative grid gap-2 bg-white p-2 sm:gap-2 sm:p-2 overflow-y-auto max-h-340"
                    >
                      {categoriesList.map((item, i) => {
                        return (
                          <Link
                            key={item.id}
                            href={item.href}
                            className="-m-3 p-2 flex items-center rounded-md text-base font-medium text-gray-500 hover:bg-gray-50 transition ease-in-out duration-150"
                          >
                            <li
                              key={item.name}
                              className="flow-root p-2 hover:bg-gray-50"
                            >
                              <a className="hover:font-semibold hover:text-black hover:opacity-100 transition duration-700 ease-in-out transform hover:scale-110">
                                {item.name}
                              </a>
                            </li>
                          </Link>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>

              {/* <div className={`${styles.hoverTriger} relative md!ml-3`}>
                <div
                  className={`text-gray-900 group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                >
                  <Link
                    href="/current-affairs"
                    className="text-base font-medium text-gray-500 hover:text-gray-900"
                  >
                    <a>Current Affairs</a>
                  </Link>
                  <ChevronDownIcon
                    className="text-gray-600 ml-2 h-5 w-5 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                </div>

                <div
                  className={`${styles.hoverTarget} absolute z-50 left-1/2 transform -translate-x-1/2 w-screen max-w-xs sm:px-0`}
                >
                  <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                    <ul
                      role="list"
                      className="relative grid gap-2 bg-white p-2 sm:gap-2 sm:p-2"
                    >
                      {currentAffairs.map((item) => (
                        <Link
                          key={item.id}
                          href={item.href}
                          className="-m-3 p-2 flex items-center rounded-md text-base font-medium text-gray-500 hover:bg-gray-50 transition ease-in-out duration-150"
                        >
                          <li
                            key={item.name}
                            className="flow-root p-2 hover:bg-gray-50"
                          >
                            <a className="hover:font-semibold hover:text-black hover:opacity-100 transition duration-700 ease-in-out transform hover:scale-110">
                              {item.name}
                            </a>
                          </li>
                        </Link>
                      ))}
                    </ul>
                  </div>
                </div>
              </div> */}

              <div className={`${styles.hoverTriger} relative md!ml-3`}>
                <div
                  className={`text-gray-900 group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                >
                  <Link
                    href="#"
                    className="text-base font-medium text-gray-500 hover:text-gray-900"
                  >
                    <a>Courses</a>
                  </Link>
                  <ChevronDownIcon
                    className="text-gray-600 ml-2 h-5 w-5 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                </div>

                <div
                  className={`${styles.hoverTarget} absolute z-10 left-1/2 transform -translate-x-1/2 w-screen max-w-xs `}
                >
                  <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                    {data && data.courseCategory && (
                      <ul
                        role="list"
                        className="relative grid gap-2 bg-white p-2 sm:gap-2 sm:p-2 overflow-y-auto max-h-340"
                      >
                        {data.courseCategory.map((item, i) => {
                          return (
                            <Link
                              key={item.id}
                              href={"/course/category/" + item.slug}
                              className="-m-3 p-2 flex items-center rounded-md text-base font-medium text-gray-500 hover:bg-gray-50 transition ease-in-out duration-150 cursor-pointer"
                            >
                              <li
                                key={item.name.en}
                                className="flow-root p-2 hover:bg-gray-50"
                              >
                                <a className="hover:font-semibold hover:text-black hover:opacity-100 transition duration-700 ease-in-out transform hover:scale-110">
                                  {item.name.en}
                                </a>
                              </li>
                            </Link>
                          );
                        })}
                      </ul>
                    )}
                  </div>
                </div>
              </div>

              <div className={`${styles.hoverTriger} relative md!ml-3`}>
                <div
                  className={`text-gray-900 group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                >
                  <Link
                    href="#"
                    className="text-base font-medium text-gray-500 hover:text-gray-900"
                  >
                    <a>Test Series</a>
                  </Link>
                  <ChevronDownIcon
                    className="text-gray-600 ml-2 h-5 w-5 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                </div>

                <div
                  className={`${styles.hoverTarget} absolute z-10 left-1/2 transform -translate-x-1/2 w-screen max-w-xs `}
                >
                  <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                    {data && data.testSeriesCategory && (
                      <ul
                        role="list"
                        className="relative grid gap-2 bg-white p-2 sm:gap-2 sm:p-2 overflow-y-auto max-h-340"
                      >
                        {data.testSeriesCategory.map((item, i) => {
                          return (
                            <Link
                              key={item.id}
                              href={"/test-series/category/" + item.slug}
                              className="-m-3 p-2 flex items-center rounded-md text-base font-medium text-gray-500 hover:bg-gray-50 transition ease-in-out duration-150 cursor-pointer"
                            >
                              <li
                                key={item.name.en}
                                className="flow-root p-2 hover:bg-gray-50"
                              >
                                <a className="hover:font-semibold hover:text-black hover:opacity-100 transition duration-700 ease-in-out transform hover:scale-110">
                                  {item.name.en}
                                </a>
                              </li>
                            </Link>
                          );
                        })}
                      </ul>
                    )}
                  </div>
                </div>
              </div>

              <div className="lg:!ml-12">
                <Link
                  href="/contact"
                  className="text-base font-medium text-gray-500 hover:text-gray-900"
                >
                  Contact Us
                </Link>
              </div>
            </Popover.Group>
            <div className="flex items-center justify-center px-2 lg:ml-8">
              <SearchForm />
            </div>
          </div>
          <div>
            {!isAuthenticated && (
              <div
                onClick={() => setOpenLoginModal(true)}
                className="lg:!ml-3 lg:!ml-12 mx-8"
              >
                <div className="text-base bg-gray-50 border border-1 border-gray-200 shadow-md !px-5 py-1.5 tracking-wider rounded-lg font-medium text-tn-purple cursor-pointer">
                  Login
                </div>
              </div>
            )}
            {isAuthenticated && (
              <div className="flex flex-row mx-8 items-center">
                {/* <div className="lg:!ml-3 lg:!ml-12">
                  <div className="text-base tracking-wider font-semibold text-gray-500 hover:text-[#7c5ef9] cursor-pointer">
                    {user && user.name}
                  </div>
                </div> */}
                <div className="lg:!ml-3 lg:!ml-12">
                  <Menu as="div" className="relative inline-block text-left">
                    <Menu.Button className="flex flex-row items-center">
                      <img
                        onClick={() => setDropdown(!dropdown)}
                        className="w-10 h-10 rounded-full cursor-pointer"
                        // src="https://toppernotes.s3.ap-south-1.amazonaws.com/images/profiles/f6Mx15mMFkJtJBTYZuAC.png"
                        src={
                          user && user.img
                            ? user.img
                            : convertToImageKit(defaultUser, "app") +
                              "?tr=w-240"
                        }
                        alt="User dropdown"
                      />
                      <AiFillCaretDown />
                    </Menu.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="!z-20 absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="px-1 py-1 ">
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                className={`group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                              >
                                {user && (
                                  <span className="text-start tracking-wider">
                                    Signed in as
                                    <br />
                                    <span className="text-[#7c5efb] text-lg text-start font-semibold">
                                      {user.name ? user.name : user.contact}
                                    </span>
                                  </span>
                                )}
                              </button>
                            )}
                          </Menu.Item>
                        </div>
                        <div className="px-1 py-1">
                          <Menu.Item>
                            {({ active }) => (
                              <div
                                className={`${
                                  active
                                    ? "bg-violet-500 text-white"
                                    : "text-gray-900"
                                } group flex w-full items-center rounded-md px-2 py-2 text-sm cursor-pointer`}
                              >
                                <Link href="/update-profile">
                                  <span
                                    className={`${
                                      active && "text-white"
                                    } flex flex-row gap-2 items-center`}
                                  >
                                    <BiUser /> Update Profile
                                  </span>
                                </Link>
                              </div>
                            )}
                          </Menu.Item>
                        </div>
                        <div className="px-1 py-1">
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                onClick={() => {
                                  setOpenLoginModal(true);
                                  dispatch(logout(toast));
                                }}
                                className={`${
                                  active
                                    ? "bg-violet-500 text-white"
                                    : "text-gray-900"
                                } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                              >
                                <FiLogOut className="mr-2" />
                                Sign Out
                              </button>
                            )}
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* <-- Mobile Screen --> */}

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute z-20 top-0 inset-x-0 p-2 transition transform origin-top-right lg:hidden"
        >
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
            <div className="pt-5 pb-6 px-2">
              <div className="flex items-center justify-between">
                <div>
                  <Link href="/">
                    {/* <Image
                      unoptimized={true}
                      loader={myLoader}
                      width={160}
                      height={65}
                      src={convertToImageKit(header)}
                      alt="Navbar"
                      priority={true}
                    /> */}
                    <LazyLoad once>
                      <img
                        width={160}
                        height={65}
                        src={convertToImageKit(header)}
                        alt="Navbar"
                      />
                    </LazyLoad>
                  </Link>
                </div>
                <div className="-mr-2">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="mt-6">
                <Popover.Group as="nav" className="flex-col space-x-10">
                  <Link href="/featured/" className="">
                    <div className="p-2 text-gray-500 hover:text-gray-900 text-base font-medium">
                      Featured Courses
                    </div>
                  </Link>
                  <div
                    onClick={() => setOpenExamStore((o) => !o)}
                    className="!ml-0 mt-3 "
                  >
                    <div>
                      <div className="flex justify-between w-100">
                        <div
                          className={classNames(
                            openExamStore ? "text-gray-900" : "text-gray-500",
                            "w-100 group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          )}
                        >
                          <div className="flex justify-between w-100 p-2">
                            <div>Notes</div>
                            <div>
                              {openExamStore ? (
                                <ChevronUpIcon
                                  className={classNames(
                                    openExamStore
                                      ? "text-gray-600"
                                      : "text-gray-400",
                                    "ml-2 h-5 w-5 group-hover:text-gray-500"
                                  )}
                                  aria-hidden="true"
                                />
                              ) : (
                                <ChevronDownIcon
                                  className={classNames(
                                    openExamStore
                                      ? "text-gray-600"
                                      : "text-gray-400",
                                    "ml-2 h-5 w-5 group-hover:text-gray-500"
                                  )}
                                  aria-hidden="true"
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                      >
                        <div className="relative mt-3 px-2 sm:px-0">
                          {openExamStore && (
                            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                              <ul
                                role="list"
                                className="relative grid gap-2 bg-white p-2 sm:gap-2 max-h-340 overflow-y-auto"
                              >
                                {categoriesList.map((item) => {
                                  return (
                                    <Link
                                      key={item.id}
                                      href={item.href}
                                      className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-50 transition ease-in-out duration-150"
                                    >
                                      <li
                                        key={item.name}
                                        className="flow-root p-3 hover:bg-gray-50"
                                      >
                                        <a>{item.name}</a>
                                      </li>
                                    </Link>
                                  );
                                })}
                              </ul>
                            </div>
                          )}
                        </div>
                      </Transition>
                    </div>
                  </div>
                  {/* <div
                    onClick={() => setOpenCurrentAffairs((o) => !o)}
                    className="!ml-0 mt-3"
                  >
                    <div>
                      <div className="flex justify-between w-100">
                        <div
                          className={classNames(
                            openCurrentAffairs
                              ? "text-gray-900"
                              : "text-gray-500",
                            "w-100 group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          )}
                        >
                          <div className="flex justify-between w-100 p-2">
                            <div>Current Affairs</div>
                            <div>
                              {openCurrentAffairs ? (
                                <ChevronUpIcon
                                  className={classNames(
                                    openCurrentAffairs
                                      ? "text-gray-600"
                                      : "text-gray-400",
                                    "ml-2 h-5 w-5 group-hover:text-gray-500"
                                  )}
                                  aria-hidden="true"
                                />
                              ) : (
                                <ChevronDownIcon
                                  className={classNames(
                                    openCurrentAffairs
                                      ? "text-gray-600"
                                      : "text-gray-400",
                                    "ml-2 h-5 w-5 group-hover:text-gray-500"
                                  )}
                                  aria-hidden="true"
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                      >
                        
                        <div className="relative mt-3 px-2 sm:px-0">
                          {openCurrentAffairs && (
                            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                              <ul
                                role="list"
                                className="relative grid gap-2 bg-white p-4 sm:gap-2 max-h-340 overflow-y-auto"
                              >
                                {currentAffairs.map((item) => (
                                  <Link
                                    key={item.id}
                                    href={item.href}
                                    className="-m-3 p-2 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-50 transition ease-in-out duration-150"
                                  >
                                    <li
                                      key={item.name}
                                      className="flow-root p-2 hover:bg-gray-50"
                                    >
                                      <a>{item.name}</a>
                                    </li>
                                  </Link>
                                ))}
                              </ul>
                            </div>
                          )}
                        </div>
                        
                      </Transition>
                    </div>
                  </div> */}
                  <div
                    onClick={() => setOpenCurrentAffairs((o) => !o)}
                    className="!ml-0 mt-3"
                  >
                    <div>
                      <div className="flex justify-between w-100">
                        <div
                          className={classNames(
                            openCurrentAffairs
                              ? "text-gray-900"
                              : "text-gray-500",
                            "w-100 group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          )}
                        >
                          <div className="flex justify-between w-100 p-2">
                            <div>Courses</div>
                            <div>
                              {openCurrentAffairs ? (
                                <ChevronUpIcon
                                  className={classNames(
                                    openCurrentAffairs
                                      ? "text-gray-600"
                                      : "text-gray-400",
                                    "ml-2 h-5 w-5 group-hover:text-gray-500"
                                  )}
                                  aria-hidden="true"
                                />
                              ) : (
                                <ChevronDownIcon
                                  className={classNames(
                                    openCurrentAffairs
                                      ? "text-gray-600"
                                      : "text-gray-400",
                                    "ml-2 h-5 w-5 group-hover:text-gray-500"
                                  )}
                                  aria-hidden="true"
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                      >
                        {/* <Popover.Panel className="relative mt-3 px-2 sm:px-0"> */}
                        <div className="relative mt-3 px-2 sm:px-0">
                          {openCurrentAffairs && (
                            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                              {data && data.courseCategory && (
                                <ul
                                  role="list"
                                  className="relative grid gap-2 bg-white p-4 sm:gap-2 max-h-340 overflow-y-auto"
                                >
                                  {data.courseCategory.map((item) => (
                                    <Link
                                      key={item.id}
                                      href={"/course/category/" + item.slug}
                                      className="-m-3 p-2 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-50 transition ease-in-out duration-150"
                                    >
                                      <li
                                        key={item.name.en}
                                        className="flow-root p-2 hover:bg-gray-50"
                                      >
                                        <a>{item.name.en}</a>
                                      </li>
                                    </Link>
                                  ))}
                                </ul>
                              )}
                            </div>
                          )}
                        </div>
                        {/* </Popover.Panel> */}
                      </Transition>
                    </div>
                  </div>
                  <div
                    onClick={() => setOpenTestSeries((o) => !o)}
                    className="!ml-0 mt-3"
                  >
                    <div>
                      <div className="flex justify-between w-100">
                        <div
                          className={classNames(
                            openTestSeries ? "text-gray-900" : "text-gray-500",
                            "w-100 group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          )}
                        >
                          <div className="flex justify-between w-100 p-2">
                            <div>Test Series</div>
                            <div>
                              {openTestSeries ? (
                                <ChevronUpIcon
                                  className={classNames(
                                    openTestSeries
                                      ? "text-gray-600"
                                      : "text-gray-400",
                                    "ml-2 h-5 w-5 group-hover:text-gray-500"
                                  )}
                                  aria-hidden="true"
                                />
                              ) : (
                                <ChevronDownIcon
                                  className={classNames(
                                    openTestSeries
                                      ? "text-gray-600"
                                      : "text-gray-400",
                                    "ml-2 h-5 w-5 group-hover:text-gray-500"
                                  )}
                                  aria-hidden="true"
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                      >
                        <div className="relative mt-3 px-2 sm:px-0">
                          {openTestSeries && (
                            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                              {data && data.testSeriesCategory && (
                                <ul
                                  role="list"
                                  className="relative grid gap-2 bg-white p-4 sm:gap-2 max-h-340 overflow-y-auto"
                                >
                                  {data.testSeriesCategory.map((item) => (
                                    <Link
                                      key={item.id}
                                      href={
                                        "/test-series/category/" + item.slug
                                      }
                                      className="-m-3 p-2 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-50 transition ease-in-out duration-150"
                                    >
                                      <li
                                        key={item.name.en}
                                        className="flow-root p-2 hover:bg-gray-50"
                                      >
                                        <a>{item.name.en}</a>
                                      </li>
                                    </Link>
                                  ))}
                                </ul>
                              )}
                            </div>
                          )}
                        </div>
                      </Transition>
                    </div>
                  </div>
                  <Link href="/contact" className="">
                    <div className="!ml-0 mt-3 p-2 text-gray-500 hover:text-gray-900 text-base font-medium">
                      Contact Us
                    </div>
                  </Link>
                  {!isAuthenticated && (
                    <div
                      onClick={() => setOpenLoginModal(true)}
                      className="!ml-0 mt-3 p-2 w-4/12 text-center"
                    >
                      <div className="text-base bg-gray-50 border border-1 border-gray-300 !px-5 py-1.5 tracking-wider rounded-lg font-medium text-tn-purple shadow-md cursor-pointer">
                        Login
                      </div>
                    </div>
                  )}
                  {isAuthenticated && (
                    <>
                      <div className="!ml-0 text-base font-medium text-gray-500 hover:text-gray-900">
                        <div className="!ml-0 mt-3 p-2 flex flex-row items-center gap-1">
                          <img
                            className="w-8 h-8 rounded-full cursor-pointer"
                            // src="https://toppernotes.s3.ap-south-1.amazonaws.com/images/profiles/f6Mx15mMFkJtJBTYZuAC.png"
                            src={
                              user && user.img
                                ? user.img
                                : convertToImageKit(defaultUser, "app") +
                                  "?tr=w-240"
                            }
                            alt="User dropdown"
                          />
                          {user && (user.name ? user.name : user.contact)}
                        </div>
                      </div>
                      <div className="!ml-0 text-base font-medium text-gray-500 hover:text-gray-900">
                        <div className="!ml-0 mt-3 p-2">
                          <Link href="/update-profile">
                            <span className="text-gray-500 flex flex-row gap-2 items-center">
                              <BiUser /> Update Profile
                            </span>
                          </Link>
                        </div>
                      </div>
                      <div
                        onClick={() => {
                          setOpenLoginModal(true);
                          dispatch(logout(toast));
                        }}
                        className="!ml-0 text-base font-medium text-gray-500 hover:text-gray-900"
                      >
                        <div className="!ml-0 mt-3 p-2 flex flex-row gap-2 items-center">
                          <FiLogOut />
                          Sign out
                        </div>
                      </div>
                    </>
                  )}
                </Popover.Group>
              </div>
            </div>
            <div className="py-6 px-5">
              <SearchForm />
            </div>
          </div>
        </Popover.Panel>
      </Transition>
      {openLoginModal && <Login setOpenLoginModal={setOpenLoginModal} />}
    </Popover>
  );
}

export default Header;
